import React, { useMemo, useCallback, useState } from 'react'
import { useHistory } from 'react-router-dom'
import styled, { css } from 'styled-components'
import { CheckSquare, Square } from 'styled-icons/fa-regular'
import { ArrowRight, Lock } from 'styled-icons/fa-solid'
import { isBefore } from 'date-fns'

import ExercisesAPI from 'datasources/exercises'
import usePromise from 'hooks/usePromise'
import useAppCtx from 'hooks/useAppCtx'
import useAuthCtx from 'hooks/useAuthCtx'
import FlexColumn from 'components/FlexColumn'
import FlexRow from 'components/FlexRow'

import useExercisesCtx from '../../hooks/useExercisesCtx'
import ProgressCircle from '../../components/ProgressCircle'

const Container = styled(FlexRow)`
  background-color: #f5f9fa;
  border-radius: 10px;
  padding: 30px;
  @media only screen and (max-width: 565px) {
    flex-direction: column;
    padding: 30px 20px;
  }
`

const LessonsContainer = styled(FlexColumn)``

const ResultsContainer = styled(FlexColumn)`
  margin-left: 30px;
  max-width: 370px;
  @media only screen and (max-width: 565px) {
    margin-left: 0;
  }
`

const ResultsHeading = styled.div`
  color: #676a74;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin-top: 32px;
  margin-bottom: 40px;
  @media only screen and (max-width: 1024px) {
    margin-top: 54px;
  }
`

const Name = styled.div`
  color: #676a74;
  font-size: 18px;
  font-weight: bold;
  line-height: 22px;
  margin-bottom: 10px;
`

const Instructions = styled.div`
  color: #9da3a8;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 40px;
`

const ResultsBox = styled.div`
  background-color: white;
  border-radius: 10px;
  border: solid 2px #e8eaee;
  padding: 30px;
  padding-bottom: 0;
  margin-bottom: 40px;
  min-height: 366px;
  @media only screen and (max-width: 565px) {
    background-color: #f5f9fa;
    border: none;
    padding: 0;
    margin: 0;
    min-height: 0;
  }
`

const LessonsBox = styled.div`
  background-color: white;
  border-radius: 10px;
  border: solid 2px #e8eaee;
  padding: 30px;
  padding-bottom: 0;
  margin-bottom: 40px;
  @media only screen and (max-width: 565px) {
    background-color: #f5f9fa;
    border: none;
    padding: 0;
    margin-bottom: 0;
  }
`

const LessonsList = styled.div`
  border-bottom: solid 1px #e8eaee;
  height: 250px;
  overflow-y: scroll;
  width: 100%;
  margin-bottom: 30px;
  @media only screen and (max-width: 565px) {
    height: auto;
    overflow-y: auto;
  }
`

const Lesson = styled(FlexRow)`
  color: #9da3a8;
  cursor: pointer;
  font-size: 18px;
  line-height: 22px;
  margin-bottom: 30px;
  user-select: none;
  > svg {
    margin-right: 10px;
    flex-shrink: 0;
    ${({ checked }) =>
      checked &&
      css`
        color: #1cace3;
      `}
  }
  @media only screen and (max-width: 565px) {
    font-size: 14px;
  }
`

const Button = styled.button`
  background-color: #1cace3;
  border-radius: 4px;
  color: #fff;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  font-size: 18px;
  font-weight: 500;
  line-height: 40px;
  margin-left: auto;
  outline: 0;
  padding: 0 25px;
  ${({ disabled }) =>
    disabled &&
    css`
      background-color: #d7dde2;
      color: #fff;
    `};
  @media only screen and (max-width: 565px) {
    width: 100%;
    margin-top: 20px;
  }
`

const SummaryContainer = styled(FlexRow)`
  @media only screen and (max-width: 565px) {
    flex-direction: column;
  }
`

const Summary = styled.div`
  align-self: center;
  color: #676a74;
  font-size: 18px;
  line-height: 22px;
  @media only screen and (max-width: 565px) {
    align-self: flex-start;
  }
`

const Result = styled(FlexRow)`
  font-size: 16px;
  line-height: 42px;
  color: #9da3a8;
  margin-bottom: 30px;
  align-items: center;
  @media only screen and (max-width: 565px) {
    margin-bottom: 10px;
  }
`

function useExerciseStats(topicId) {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(
    function () {
      return ExercisesAPI.stats(ctx, topicId)
    },
    [topicId],
  )

  return {
    stats: result && result.data,
    isFetchingStats: !isResolved,
  }
}

function useSelectedLessons(allLessons) {
  const [data, setData] = useState([])

  const toggle = function (lesson) {
    return function () {
      if (data.find(x => x === lesson.id)) {
        setData(data.filter(x => x !== lesson.id))
        return
      }
      setData([...data, lesson.id])
    }
  }

  const selectAll = function () {
    setData(allLessons.map(x => x.id))
  }

  const clear = function () {
    setData([])
  }

  return { selected: data, toggle, selectAll, clear }
}

export default function () {
  const history = useHistory()
  const { bundles } = useAuthCtx()
  const { currCourse, currTopic } = useExercisesCtx()
  const { selected, selectAll, toggle, clear } = useSelectedLessons(
    currTopic.lessons,
  )

  const { isFetchingStats, stats } = useExerciseStats(currTopic.id)

  const expired = useMemo(() => {
    const ibselBundle = bundles.find(x => x.bundleId === 1)
    if (!ibselBundle) return true
    return !isBefore(new Date(), new Date(ibselBundle.expiresAt))
  }, [bundles])

  const allSelected = expired
    ? selected.length === 1
    : selected.length === currTopic.lessons.length

  const noneSelected = selected.length === 0

  const summary = (() => {
    if (selected.length === 0) {
      return 'Nenhum tema selecionado'
    }
    if (selected.length === 1) {
      return '1 tema selecionado'
    }
    if (selected.length > 1) {
      return `${selected.length} temas selecionados`
    }
  })()

  const onStart = useCallback(() => {
    history.push(
      `/questoes/${currCourse.id}/${currTopic.id}/${selected.join(
        '-',
      )}/${Math.round(Math.random() * 99)}`,
    )
  }, [currCourse, currTopic, history, selected])

  return (
    <Container>
      <LessonsContainer>
        <Name>{currTopic.name}</Name>
        <Instructions>
          Escolha um tema ou responda questões de toda a matérias
        </Instructions>
        <LessonsBox>
          <LessonsList>
            {currTopic.lessons.map((lesson, i) => {
              const checked = selected.find(x => x === lesson.id)
              return (
                <Lesson
                  key={i}
                  onClick={expired && i > 0 ? () => {} : toggle(lesson)}
                  checked={checked}
                >
                  {expired && i > 0 ? (
                    <Lock size='20' />
                  ) : checked ? (
                    <CheckSquare size='20' />
                  ) : (
                    <Square size='20' />
                  )}
                  {lesson.name}
                </Lesson>
              )
            })}
          </LessonsList>
          <Lesson
            onClick={
              allSelected
                ? clear
                : expired
                ? toggle(currTopic.lessons[0])
                : selectAll
            }
            checked={allSelected}
          >
            {allSelected ? <CheckSquare size='20' /> : <Square size='20' />}
            Selecionar todas
          </Lesson>
        </LessonsBox>
        <SummaryContainer>
          <Summary>{summary}</Summary>
          <Button disabled={noneSelected} onClick={onStart}>
            Iniciar <ArrowRight size='18' />
          </Button>
        </SummaryContainer>
      </LessonsContainer>
      {!isFetchingStats && (
        <ResultsContainer>
          <ResultsHeading>Resultados</ResultsHeading>
          <ResultsBox>
            <Result>
              <ProgressCircle
                percent={stats.dayPerformance}
                text={`${stats.dayPerformance}%`}
              />
              Taxa de acerto hoje
            </Result>
            <Result>
              <ProgressCircle
                percent={Math.round(
                  (stats.totalAnswered / stats.totalQuestions) * 100,
                )}
                text={`${stats.totalAnswered} / ${stats.totalQuestions}`}
              />
              Perguntas respondidas
            </Result>
            <Result>
              <ProgressCircle
                percent={stats.performance}
                text={`${stats.performance}%`}
              />
              Taxa de acerto total
            </Result>
          </ResultsBox>
        </ResultsContainer>
      )}
    </Container>
  )
}
