import React from 'react'
import { Link } from 'react-router-dom'
import { Envelope, Lock } from 'styled-icons/fa-solid'
import styled from 'styled-components'

import AuthAPI from 'datasources/auth'
import useForm from 'hooks/useForm'
import useAppCtx from 'hooks/useAppCtx'
import mixpanel from 'utils/mixpanel'
import Logo from 'routes/Anonymous/components/Logo'
import Input from 'routes/Anonymous/components/Input'
import Form from 'routes/Anonymous/components/Form'
import Title from 'routes/Anonymous/components/Title'
import Subtitle from 'routes/Anonymous/components/Subtitle'
import Submit from 'routes/Anonymous/components/Submit'
import AltAction from 'routes/Anonymous/components/AltAction'

import useUniversityCtx from 'hooks/useUniversityCtx'
import { getImgixUrl } from 'utils/imgix'

const LogoUni = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`

const Cover = styled.img`
  filter: contrast(40%) brightness(170%);
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  width: 110px;
  @media only screen and (max-width: 565px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`
export const SubtitleRed = styled.h2`
  background-color: #ff595e;
  border-radius: 5px;
  font-size: 16px;
  font-weight: normal;
  line-height: 38px;
  margin-bottom: 60px;
  margin-top: 0;
  text-align: center;
  @media only screen and (max-width: 565px) {
    font-size: 20px;
    line-height: 30px;
    padding: 10px;
    margin-top: 10px;
  }
`

const initial = {
  email: '',
  password: '',
}

const constraints = {
  email: { presence: { allowEmpty: false } },
  password: { presence: { allowEmpty: false } },
}

const errorsMap = {
  shouldBeValid: 'Email e/ou senha inválido(s)',
}

export default function ({ history }) {
  const ctx = useAppCtx()
  const { universities } = useUniversityCtx()

  const { fields, values, setAllErrors, onSubmit } = useForm(initial, {
    constraints,
    errorsMap,
  })

  const submit = async () => {
    try {
      const { data: tokens } = await AuthAPI.createToken(ctx, values)

      ctx.auth.auth(tokens)
      history.push('/')
      mixpanel(ctx, 'User_Login', { University: 'Varejo' })
    } catch (err) {
      setAllErrors(err)
    }
  }

  const displayLogin = universities && universities.name === 'Varejo'

  return (
    <Form onSubmit={onSubmit(submit)}>
      {displayLogin ? (
        <div>
          <Logo />
          <Title>Bem-vindo</Title>
          <Subtitle>Insira os seus dados para acessar a plataforma</Subtitle>
          <Input
            id='email'
            placeholder='Email'
            icon={Envelope}
            type='email'
            autoFocus
            {...fields.email}
          />
          <Input
            id='password'
            placeholder='Password'
            icon={Lock}
            type='password'
            {...fields.password}
          />
          <Submit value='Entrar' />
          <AltAction>
            Não tem uma conta? <Link to='/registrar'>Cadastrar</Link>
            <br />
            Esqueceu sua senha? <Link to='/reiniciar-senha'>Resetar senha</Link>
          </AltAction>
        </div>
      ) : (
        <div>
          <LogoUni>
            <Cover
              src={getImgixUrl(`universities/${universities.id}/image`, {
                w: 150,
                h: 150,
              })}
            />
          </LogoUni>
          <Title>Bem-vindo</Title>
          <SubtitleRed>
            Faça seu login através do portal da sua faculdade.
          </SubtitleRed>
        </div>
      )}
    </Form>
  )
}
