import React, { useMemo, useEffect } from 'react'
import styled from 'styled-components'
import { Link } from 'react-router-dom'
import { User, Envelope, Lock } from 'styled-icons/fa-solid'

import FieldsAPI from 'datasources/fields'
import UsersAPI from 'datasources/users'
import useForm from 'hooks/useForm'
import useApi from 'hooks/useApi'
import useAppCtx from 'hooks/useAppCtx'
import mixpanel from 'utils/mixpanel'
import Logo from 'routes/Anonymous/components/Logo'
import Input from 'routes/Anonymous/components/Input'
import Select from 'routes/Anonymous/components/Select'
import Form from 'routes/Anonymous/components/Form'
import Title from 'routes/Anonymous/components/Title'
import Submit from 'routes/Anonymous/components/Submit'
import AltAction from 'routes/Anonymous/components/AltAction'

import useUniversityCtx from 'hooks/useUniversityCtx'
import { getImgixUrl } from 'utils/imgix'
import fbq from 'utils/fbq'

export const SubtitleRed = styled.h2`
  background-color: #ff595e;
  border-radius: 5px;
  font-size: 16px;
  font-weight: normal;
  line-height: 38px;
  margin-bottom: 60px;
  margin-top: 0;
  text-align: center;
  @media only screen and (max-width: 565px) {
    font-size: 20px;
    line-height: 30px;
    padding: 10px;
    margin-top: 10px;
  }
`

const Cover = styled.img`
  filter: contrast(40%) brightness(170%);
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  width: 110px;
  @media only screen and (max-width: 565px) {
    width: 100%;
    height: auto;
    margin-bottom: 20px;
  }
`
const LogoUni = styled.div`
  text-align: center;
  display: flex;
  justify-content: center;
`

const initial = {
  name: '',
  email: '',
  password: '',
  fieldId: '',
  // inviteCode: "",
}

const constraints = {
  name: { presence: { allowEmpty: false } },
  email: { presence: { allowEmpty: false } },
  password: { presence: { allowEmpty: false } },
  fieldId: { presence: { allowEmpty: false } },
}

const errorsMap = {
  shouldBeValid: 'É inválido',
  shouldBeUnique: 'Já está cadastrado',
}

function useFieldsList() {
  const { data: fieldsList, isFetching: isFetchingFieldsList } = useApi(
    FieldsAPI.list,
  )
  return {
    fieldsList,
    isFetchingFieldsList,
  }
}

export default function({ history }) {
  const ctx = useAppCtx()
  const { universities } = useUniversityCtx()

  const { fieldsList, isFetchingFieldsList } = useFieldsList()

  const { fields, values, setAllErrors, onSubmit } = useForm(initial, {
    constraints,
    errorsMap,
  })

  useEffect(function() {
    fbq('Iniciar Registro')
  }, [])

  const field = useMemo(
    () => (fieldsList || []).find(x => x.id === values.fieldId),
    [fieldsList, values.fieldId],
  )

  const submit = async () => {
    try {
      const host = `${window.location.protocol}//${window.location.host}`

      const body = { ...values, universityId: 15 }
      const { data: tokens } = await UsersAPI.create(ctx, { ...body, host })
      ctx.auth.auth(tokens)
      history.push('/')

      fbq('Registered')
      mixpanel(ctx, 'User_Registered', {
        Field_Name: field ? field.name : '',
      })
    } catch (err) {
      setAllErrors(err)
    }
  }

  return (
    <Form onSubmit={onSubmit(submit)}>
      {universities &&
      (universities.name === 'Varejo' || universities.name === 'MedAlfa') ? (
        <div>
          <Logo />
          <Title>Bem-vindo</Title>
          <SubtitleRed>Registre-se grátis em nossa plataforma!</SubtitleRed>
          <Input
            id='name'
            placeholder='Nome completo'
            icon={User}
            autoFocus
            {...fields.name}
          />
          <Input
            id='email'
            placeholder='E-mail'
            icon={Envelope}
            type='email'
            {...fields.email}
          />
          <Input
            id='password'
            placeholder='Senha'
            icon={Lock}
            type='password'
            {...fields.password}
          />
          {!isFetchingFieldsList && (
            <Select
              label='Selecione seu curso'
              changeTransform={x => (x ? parseInt(x) : '')}
              options={fieldsList}
              {...fields.fieldId}
            />
          )}
          <Submit value='Registrar' />
          <AltAction>
            Já tem cadastro? <Link to='/entrar'>Fazer login</Link>
          </AltAction>
        </div>
      ) : (
        <div>
          <LogoUni>
            <Cover
              src={getImgixUrl(`universities/${universities?.id}/image`, {
                w: 150,
                h: 150,
              })}
            />
          </LogoUni>
          <Title>Bem-vindo</Title>
          <SubtitleRed>
            Faça seu login através do portal da sua faculdade.
          </SubtitleRed>
        </div>
      )}
    </Form>
  )
}
