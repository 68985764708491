import React from "react"
import { Route } from "react-router-dom"

import useAuthCtx from "hooks/useAuthCtx"
import Anonymous from "routes/Anonymous"
import Authenticated from "routes/Authenticated"

export default function() {
  const { authed, emailConfirmed } = useAuthCtx()

  return (
    <Route render={({ history, match }) =>
      authed && emailConfirmed ?
        <Authenticated history={history} match={match}/> : <Anonymous/>
    }/>
  )
}