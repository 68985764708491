import styled, { css } from "styled-components"
import FlexRow from "components/FlexRow"
import ListMarkerSVG from "components/icons/ListMarkerSVG"
import { Lock } from "styled-icons/fa-solid"

const Tabs = styled(FlexRow)`
  height: 40px;
  margin-bottom: 40px;
  @media only screen and (max-width: 565px) {
    display: block;
    height: auto !important;
    margin-bottom: 20px;
    border: 1px solid #1CACE3;
    background-color: #fff;
  }
`

const Tab = styled.div`
  @media only screen and (max-width: 565px) {
    width: 100%;
    margin-bottom: 0;
    text-align: left;
    display: flex;
    align-items: center;
    background-color: rgba(255,255,255, 0.5);
    padding: 11px 10px;
    height: 55px;

    &:nth-child(1) {
      svg {
        fill: rgb(217,98,121);
      }
    }

    &:nth-child(2) {
      svg {
        fill: rgb(91,166,146);
      }
    }

    &:nth-child(3) {
      svg {
        fill: rgb(28,172,227);
      }
    }

    ${({ selected }) => selected && css`
      border-top: none;
      &:nth-child(1) {
        background-color: rgba(217,98,121, 0.2);
      }
      &:nth-child(2) {
        background-color: rgba(91,166,146, 0.2);
      }
      &:nth-child(2) {
        background-color: rgba(28,172,227, 0.2);
      }
    `}
  }
`

const Bullet = styled(ListMarkerSVG)`
  display: none;
  height: 30px;
  width: 30px;
  margin-right: 5px;
  @media only screen and (max-width: 565px) {
    display: block;
  }
`

const TabLock = styled(Lock)`
  width: 14px;
  margin-left: 10px;
`

Tabs.item = Tab
Tabs.bullet = Bullet
Tabs.Lock = TabLock

export default Tabs