import styled from "styled-components"

export const AltAction = styled.div`
  font-size: 16px;
  margin-top: 60px;
  text-align: center;
  & > a, & > span {
    text-decoration: underline;
    cursor: pointer;
    color: #FFFFFF;
  }
`

export default AltAction