/* eslint-disable react-hooks/exhaustive-deps */
import React from 'react'

import { createElement, Fragment, useEffect, useRef, useState } from 'react'
import { render } from 'react-dom'

import { usePagination, useSearchBox } from 'react-instantsearch-hooks'
import { autocomplete, getAlgoliaResults } from '@algolia/autocomplete-js'

import { debounce } from '@algolia/autocomplete-shared'

import '@algolia/autocomplete-theme-classic'
import { useHistory } from 'react-router-dom/cjs/react-router-dom.min'

export const ALGOLIA_INDEX = 'ibsel'
const DEBOUNCE_TIME = 1800

function debouncePromise(fn, time) {
  let timerId = undefined

  return function debounced(...args) {
    if (timerId) {
      clearTimeout(timerId)
    }

    return new Promise(resolve => {
      timerId = setTimeout(() => resolve(fn(...args)), time)
    })
  }
}

const debounced = debouncePromise(items => Promise.resolve(items), 300)

export function Autocomplete({
  searchClient,
  className,
  ...autocompleteProps
}) {
  const history = useHistory()
  const autocompleteContainer = useRef(null)

  const { query, refine: setQuery } = useSearchBox()
  const { refine: setPage } = usePagination()

  const [instantSearchUiState, setInstantSearchUiState] = useState({ query })
  const debouncedSetInstantSearchUiState = debounce(
    setInstantSearchUiState,
    DEBOUNCE_TIME,
  )

  useEffect(() => {
    setQuery(instantSearchUiState.query)
    setPage(0)
  }, [instantSearchUiState])

  useEffect(() => {
    if (!autocompleteContainer.current) {
      return
    }

    const autocompleteInstance = autocomplete({
      ...autocompleteProps,
      container: autocompleteContainer.current,
      initialState: { query },
      insights: true,
      getSources({ query }) {
        return debounced([
          {
            sourceId: 'algoliaItems',
            getItems() {
              return getAlgoliaResults({
                searchClient,
                queries: [
                  {
                    indexName: ALGOLIA_INDEX,
                    query,
                    params: {
                      hitsPerPage: 5,
                      highlightPreTag: '<strong>',
                      highlightPostTag: '</strong>',
                    },
                  },
                ],
              })
            },
            onSelect({ item }) {
              history.push(
                `/disciplinas/${item.topic}/modulos/${item.lesson}/assuntos/${item.subject}`,
              )
              setInstantSearchUiState({
                query: item.query,
              })
            },
            templates: {
              item({ item }) {
                const { _highlightResult } = item
                const { videoname } = _highlightResult

                return (
                  <div
                    style={{
                      padding: 10,
                      zIndex: 999999,
                    }}
                  >
                    {videoname?.value ? (
                      <div
                        dangerouslySetInnerHTML={{ __html: videoname?.value }}
                      />
                    ) : (
                      item.videoname
                    )}
                  </div>
                )
              },
            },
          },
        ])
      },
      onReset() {
        setInstantSearchUiState({ query: '' })
      },
      onSubmit({ state }) {
        setInstantSearchUiState({ query: state.query })
      },
      onStateChange({ prevState, state }) {
        if (prevState.query !== state.query) {
          debouncedSetInstantSearchUiState({
            query: state.query,
          })
        }
      },
      renderer: { createElement, Fragment, render },
    })

    return () => autocompleteInstance.destroy()
  }, [])

  return <div className={className} ref={autocompleteContainer} />
}
