import React from 'react'
import styled from 'styled-components'
import { NavLink } from 'react-router-dom'
import FlexRow from 'components/FlexRow'
import FlexColumn from 'components/FlexColumn'
import { Clock, Check } from 'styled-icons/fa-solid'
import usePlaylistItemsCtx from '../../../hooks/usePlaylistItemssCtx'

const ItemTypes = styled(FlexColumn)`
  display: flex;
  padding-bottom: 20px;
`

const ItemType = styled(FlexRow)`
  /* align-items: center; */
  margin-bottom: 30px;

  &:hover {
    opacity: 0.8;
  }
`

const ItemLink = styled(NavLink)`
  min-width: 0;
  display: flex;
  text-decoration: none;
  color: #3f424a;
  line-height: 22px;
  font-weight: 600;
`

const DescriptionColumn = styled(FlexColumn)`
  align-items: center;
  padding-top: 2px;
`

const DurationRow = styled(FlexRow)`
  font-size: 12px;
  cursor: pointer;
  & svg {
    flex-shrink: 0;
    fill: #676a74;
  }
  line-height: 0px;
  font-weight: 600;
  color: #3f424a;
  margin-bottom: 10px;
`
const Duration = styled.span`
  align-self: center;
`

const ClockIcon = styled(Clock)`
  align-self: center;
  margin-right: 5px;
`

const CheckIcon = styled(Check)`
  align-self: center;
  color: #4ec437;
  margin-right: 10px;
`

const LessonRow = styled(FlexRow)`
  color: #9da3a8;
  font-size: 12px;
  cursor: pointer;
  font-weight: 500;
  padding-bottom: 5px;
`

const Lesson = styled.span`
  align-self: center;
`

const Title = styled(NavLink)`
  white-space: normal;
  min-width: 0;
  display: inline-block;
  padding-right: 15px;
  text-decoration: none;
  color: #3f424a;
  line-height: 22px;
  font-weight: 600;

  @media only screen and (max-width: 750px) {
    font-size: 14px;
    line-height: 19px;
  }
`

const ThumbnailColumn = styled(FlexColumn)`
  width: 180px;
  margin-right: 25px;
  display: flex;
  justify-content: center;
  align-items: center;

  @media only screen and (max-width: 750px) {
    width: 140px;
    margin-right: 10px;
  }
`

const Thumbnail = styled.img`
  height: 100px;
  width: 180px;
  border-radius: 10px;
  object-fit: cover;
  object-position: center;
  cursor: pointer;

  @media only screen and (max-width: 750px) {
    width: 140px;
    height: 80px;
  }
`

const PlayButton = styled.div`
  width: 40px;
  height: 40px;
  background: white;
  border: none;
  border-radius: 100%;
  margin: auto;
  cursor: pointer;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: rgba(0, 0, 0, 0.16) 3px 3px 8px;

  &::after {
    content: '';
    display: inline-block;
    position: relative;
    top: 1px;
    left: 3px;
    border-style: solid;
    border-width: 8px 0 8px 16px;
    border-color: transparent transparent transparent #1cace3;
  }
`

function Subjects() {
  const { playlistItems } = usePlaylistItemsCtx()

  return (
    <ItemTypes>
      {playlistItems.items.map(item => {
        return (
          <ItemType key={item.id}>
            <ItemLink to={item.uri}>
              <ThumbnailColumn>
                <PlayButton />
                <Thumbnail
                  src={`${process.env.PUBLIC_URL}/file-imgs/thumb.jpg`}
                />
              </ThumbnailColumn>
              <DescriptionColumn>
                <DurationRow>
                  {item.watched ? (
                    <CheckIcon size='10' />
                  ) : (
                    <ClockIcon size='10' />
                  )}
                  <Duration>
                    {new Date(item.duration * 1000)
                      .toISOString()
                      .substr(11, 8)
                      .substring(3)}
                  </Duration>
                </DurationRow>
                <LessonRow>
                  <Lesson>{item.lesson}</Lesson>
                </LessonRow>
                <FlexRow>
                  <Title to={item.uri}>{item.title}</Title>
                </FlexRow>
              </DescriptionColumn>
            </ItemLink>
          </ItemType>
        )
      })}
    </ItemTypes>
  )
}

export default Subjects
