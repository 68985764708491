import React from "react"
import { Link } from "react-router-dom"
import styled, { css } from "styled-components"
import { Eye, Clock } from "styled-icons/fa-regular"

import FlexRow from "components/FlexRow"
import FlexColumn from "components/FlexColumn"
import { getImgixUrl } from "utils/imgix"

const Container = styled(FlexColumn)`
  align-self: center;
  flex-shrink: 0;
  flex-grow: 0;
  height: 200px;
  margin-left: 15px;
  margin-right: 15px;
  position: relative;
  box-shadow: 1px 2px 2px 0px #044c74;
  width: 235px;
  ${({ topicId, lessonId, image }) => image && css`
    background-image: url("${getImgixUrl(`topics/${topicId}/lessons/${lessonId}/image`, { w: 235, h: 200})}");
  `};
  background-color: #aaa;
`

const Name = styled(FlexColumn)`
  padding: 16px;
  color: #fff;
  font-size: 16px;
  font-weight: 500;
  line-height 24px;
`

const Actions = styled(FlexRow)`
  -webkit-backdrop-filter: blur(5px);
  align-items: center;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.05);
  border-bottom-left-radius: 8px;
  border-bottom-right-radius: 8px;
  color: #FFFFFF;
  height: 45px;
  justify-content: center;
  margin-top: auto;
  padding: 0 10px;
  width: 100%;
  & > .clock {
    margin-left: auto;
    margin-right: 10px;
  }
}`;

const Watch = styled(Link)`
  display: inline-block;
  color: #FFFFFF;
  border: solid 1px #FFFFFF;
  font-size: 15px;
  line-height: 28px;
  height: 28px;
  border-radius: 3px;
  text-align: center;
  text-decoration: none;
  padding: 0 20px;
`

const Duration = styled.span`
  color: #fff;
  font-size: 14px;
  margin-right: 10px;
`

const formatDuration = v => {
  const minutes = Math.floor(v / 60);
  const seconds = (v % 60).toString().padStart(2, '0');
  return `${minutes}:${seconds}`
}

export function Lesson({ topicId, id, name, image, className, duration, watched }) {
  return (
    <Container topicId={topicId} lessonId={id} image={image} className={className}>
      <Name>
        {name}
      </Name>
      <Actions>
        {watched && <Eye size="22"/>}
        <Clock className="clock" size="18"/>
        <Duration>{formatDuration(duration)}</Duration>
        <Watch to={`/disciplinas/${topicId}/modulos/${id}`}>Assistir</Watch>
      </Actions>
    </Container>
  )
}

export default Lesson