import React, {
  useState,
  useEffect,
  useMemo,
  useCallback,
  createContext,
} from 'react'
import jwtDecode from 'jwt-decode'
// import isBefore from 'date-fns/is_before'

import authApi from 'datasources/auth'
import useLoaderCtx from 'hooks/useLoaderCtx'

const KEY = 'ibselTokens.v1'

const Context = createContext()

let refreshReq

export function Provider({ children }) {
  const initial = useMemo(() => {
    const _tokens = JSON.parse(localStorage.getItem(KEY))
    const _user = _tokens ? jwtDecode(_tokens.accessToken) : undefined
    return { tokens: _tokens, user: _user }
  }, [])

  const loader = useLoaderCtx()
  const [user, setUser] = useState(initial.user)
  const [tokens, setTokens] = useState(initial.tokens)

  const { accessToken, refreshToken } = tokens || {}
  const { id: userId } = user || {}

  const auth = useCallback(_tokens => {
    setTokens(_tokens)
    setUser(jwtDecode(_tokens.accessToken))
    localStorage.setItem(KEY, JSON.stringify(_tokens))
  }, [])

  const deauth = useCallback(() => {
    setTokens(undefined)
    setUser(undefined)
    localStorage.removeItem(KEY)
  }, [])

  const refresh = useCallback(async () => {
    try {
      const ctx = { loader }
      const payload = { refreshToken, userId }
      const { data } = await authApi.createToken(ctx, payload)

      auth(data)
      refreshReq = undefined

      return data.accessToken
    } catch (err) {
      deauth()
    }
  }, [refreshToken, userId, auth, deauth, loader])

  const getJWT = useCallback(async () => {
    if (!accessToken) {
      return undefined
    }

    const expires = new Date(0)
    expires.setUTCSeconds(jwtDecode(accessToken).exp)
    if (expires.valueOf() > new Date().valueOf()) {
      return accessToken
    }

    if (!refreshReq) {
      refreshReq = refresh()
    }

    return refreshReq
  }, [accessToken, refresh])

  const emailConfirmed = useMemo(() => user && user.emailConfirmed, [user])

  const bundles = useMemo(() => {
    if (!user) {
      return []
    }

    return user.bundles
  }, [user])

  const value = {
    auth,
    deauth,
    user,
    authed: !!user,
    bundles,
    emailConfirmed,
    getJWT,
  }

  useEffect(() => {
    if (user) {
      localStorage.setItem('fieldId', user.fieldId)
    }

    if (window.jivo_api && user) {
      window.jivo_api.setContactInfo({
        name: user.name,
        email: user.email,
      })
    }
  }, [user])

  return <Context.Provider value={value}>{children}</Context.Provider>
}

export default Context
