import React, { useCallback, useEffect } from "react"
import { Route, Switch } from "react-router-dom"
import styled, { css } from "styled-components"

// import mixpanel from "utils/mixpanel"
// import useAppCtx from "hooks/useAppCtx"
import useLayoutCtx from "hooks/useLayoutCtx"

import useUniversityCtx from 'hooks/useUniversityCtx'
import usePlaylistsCtx from "../hooks/usePlaylistsCtx"

import Tabs from "components/Tabs"
import PlaylistTypeRoute from "../routes/PlaylistType"

// const TopicTabs = styled(Tabs)`
//   height: 40px;
//   margin-bottom: 40px;
// `

const Tab = styled(Tabs.item)`
  background-color: #e8eaee;
  color: #044c74;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  margin-right: 0px;
  padding: 11px 20px;
  text-align: center;
  text-transform: uppercase;
  height: 60px;
  ${({ selected }) =>
    selected &&
    css`
      border-top-color: #044c74;
      border-top: 1px solid;
      background-color: #ffffff;
      color: #044c74;
      cursor: pointer;
    `}
`

export default function({ history }) {
  // const ctx = useAppCtx()
  const { setLayoutState } = useLayoutCtx()
  const { universities } = useUniversityCtx()

  const {
    playlistTypes,
    currPlaylistType,
  } = usePlaylistsCtx()

  const playlistTypeChanged = useCallback(id => e =>
    history.push(`/listas-de-reproducao/${id}`)
  , [history])

  useEffect(() => {
    if (currPlaylistType && universities) {
      setLayoutState({ title: 'Listas de Reprodução', backLink: '/listas-de-reproducao' })
      // mixpanel(ctx, 'Playlists_Type', { "Type": currPlaylistType.id, "University": universities.name })
    } else {
      setLayoutState({ title: 'Listas de Reprodução' })
      // mixpanel(ctx, 'Playlists_Home', { "University": universities.name })
    }

    return (() => {
      setLayoutState({})
    })
  // eslint-disable-next-line
  }, [currPlaylistType, setLayoutState])

  return (
    <>
      <Tabs>
        {playlistTypes && currPlaylistType && playlistTypes.map(playlistType => {
          const isCurrPlaylistType = currPlaylistType.id === playlistType.id
          return (
            <Tab
              key={playlistType.id}
              selected={isCurrPlaylistType}
              onClick={!isCurrPlaylistType ? playlistTypeChanged(playlistType.id) : undefined}
            >
              <Tabs.bullet />
              {playlistType.name}
            </Tab>
          )
        })}
      </Tabs>

      <Switch>
        <Route exact path="/listas-de-reproducao/:playlistType" component={PlaylistTypeRoute}/>
      </Switch>
    </>
  )
}