import React, { useMemo, useState, useCallback } from 'react'
import styled, { css } from 'styled-components'
import { NavLink, useHistory } from 'react-router-dom'
import {
  ChevronLeft,
  Play,
  PuzzlePiece,
  PencilAlt,
  SignOutAlt,
  Book,
  CreditCard,
  Certificate,
  Microscope,
  Key,
  ClinicMedical,
  Comments,
} from 'styled-icons/fa-solid'
import { PlaylistPlay } from 'styled-icons/material'

//import { Whatsapp } from 'styled-icons/fa-brands'
import { isBefore, differenceInCalendarDays } from 'date-fns'
import useAuthCtx from 'hooks/useAuthCtx'
import LogoSVG from 'components/icons/LogoSVG'
import Molecule from './backgrounds/Molecule.svg'
import Topbar from './Topbar'
import useUniversityCtx from 'hooks/useUniversityCtx'
import { getImgixUrl } from 'utils/imgix'

const Logo = styled(NavLink)`
  display: flex;
  height: ${({ open }) => (open ? '70px' : '40px')};
  justify-content: center;
  margin-bottom: ${({ open }) => (open ? '30px' : '60px')};
  & > svg {
    height: 100%;
    width: auto;
  }
  @media only screen and (max-width: 565px) {
    display: ${({ open }) => (open ? 'flex' : 'none')};
    height: 40px;
  }
`

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  padding-left: ${({ open }) => (open ? '235px' : '54px')};
  width: 100%;
  @media only screen and (max-width: 565px) {
    padding-left: 0px;
  }
`

const Sidebar = styled.div`
  background-color: #314254;
  display: flex;
  flex-direction: column;
  height: 100vh;
  left: 0;
  padding-top: 20px;
  position: fixed;
  top: 0;
  width: ${({ open }) => (open ? '235px' : '54px')};
  z-index: 100;
  @media only screen and (max-width: 565px) {
    left: auto;
    width: ${({ open }) => (open ? '100%' : '0px')};
    right: 0;
    overflow-y: auto;
  }
`

const Container = styled.div`
  background-color: #ffffff;
  background-image: url(${Molecule});
  background-position: right top -50px;
  background-repeat: no-repeat;
  display: flex;
  flex-direction: column;
  padding: 40px 30px;
  width: 100%;
  min-height: calc(100vh - 80px);
  flex: 1 1 auto;
  overflow-y: auto;
  height: 0px;
  @media only screen and (max-width: 565px) {
    padding: 30px 10px;
  }

  @media only screen and (max-width: 812px) and (orientation: landscape) {
    padding: 20px 20px 45px;
  }
`

const Main = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`

const Opener = styled.div`
  align-items: center;
  background-color: #1cace3;
  border-radius: 24px;
  color: #ffffff;
  cursor: pointer;
  display: flex;
  height: 24px;
  justify-content: center;
  position: absolute;
  right: -12px;
  top: 88px;
  width: 24px;
  & > svg {
    transform: rotate(${({ open }) => (open ? '0deg' : '180deg')});
  }
  @media only screen and (max-width: 565px) {
    display: none;
  }
`

const menuStyle = css`
  color: #ffffff;
  cursor: pointer;
  display: block;
  font-size: 16px;
  font-weight: 500;
  margin-bottom: 20px;
  padding: 13px 20px;
  position: relative;
  text-decoration: none;
  &:hover {
    background-color: #e8eaee;
    color: #1cace3;
  }
`
/*
const MenuAnchor = styled.a`
  ${menuStyle}
  @media only screen and (max-width: 565px) {
    display: ${({ open }) => (open ? 'block' : 'none')};
  }
  & > svg {
    margin-right: ${({ open }) => (open ? '10px' : '0')};
  }
  ${({ open }) =>
    !open &&
    css`
      &:hover > div {
        display: block;
      }
    `};
`
*/

const activeClassName = 'nav-item-active'

const MenuLink = styled(NavLink).attrs({ activeClassName })`
  ${menuStyle}
  &.${activeClassName} {
    background-color: #ffffff0d;
    color: #1cace3;
    border-right: 4px solid #ffca3a;
  }
  @media only screen and (max-width: 565px) {
    display: ${({ open }) => (open ? 'block' : 'none')};
  }
  & > svg {
    margin-right: ${({ open }) => (open ? '10px' : '0')};
  }
  ${({ open }) =>
    !open &&
    css`
      &:hover > div {
        display: block;
      }
    `}
`

const MenuFeaturedLink = styled(NavLink).attrs({ activeClassName })`
  ${menuStyle}
  background-color: #FF595E;
  color: #ffffff;
  &:hover {
    background-color: #ff595e;
    color: #ffffff;
  }
  @media only screen and (max-width: 565px) {
    display: ${({ open }) => (open ? 'block' : 'none')};
  }
  & > svg {
    margin-right: ${({ open }) => (open ? '10px' : '0')};
  }
  ${({ open }) =>
    !open &&
    css`
      &:hover > div {
        display: block;
      }
    `}
`

const MenuDiv = styled.div`
  ${menuStyle}
  @media only screen and (max-width: 565px) {
    display: ${({ open }) => (open ? 'block' : 'none')};
  }
  & > svg {
    margin-right: ${({ open }) => (open ? '10px' : '0')};
  }
  ${({ open }) =>
    !open &&
    css`
      &:hover > div {
        display: block;
      }
    `}
`

const MenuToolTip = styled.div`
  background-color: #1cace3;
  color: #ffffff;
  display: none;
  font-size: 14px;
  left: 54px;
  padding: 3px 6px;
  position: absolute;
  top: 11px;
  white-space: nowrap;
  width: auto;
  &:before {
    border-bottom: 4px solid transparent;
    border-right: 4px solid #1cace3;
    border-top: 4px solid transparent;
    content: '';
    height: 0;
    left: -4px;
    position: absolute;
    top: 8px;
    width: 0;
  }
`

const ExpirationBadge = styled.div`
  ${menuStyle}
  background: #ffca3a;
  color: #044c74;
  font-weight: 500;
  @media only screen and (max-width: 565px) {
    display: ${({ open }) => (open ? 'block' : 'none')};
  }
  & > svg {
    margin-right: ${({ open }) => (open ? '10px' : '0')};
  }
  ${({ open }) =>
    !open &&
    css`
      &:hover > div {
        display: block;
      }
    `}
`

const EmailBanner = styled.div`
  background-color: rgb(255, 202, 58, 0.48);
  padding: 15px 30px;
`

const Cover = styled.img`
  align-self: flex-start;
  cursor: pointer;
  flex-grow: 0;
  flex-shrink: 0;
  position: relative;
  height: 100%;
  @media only screen and (max-width: 565px) {
    width: auto;
    margin-bottom: 20px;
  }
`

export default function ({ children }) {
  const history = useHistory()
  const { deauth, bundles, emailConfirmed } = useAuthCtx()
  const [open, setOpen] = useState(window.innerWidth > 640)
  const { isFetchingUniversities, universities } = useUniversityCtx()

  const logout = useCallback(() => {
    deauth()
    history.replace('/entrar')
  }, [deauth, history])

  const ibselBundle = useMemo(() => {
    return bundles.find(x => x.bundleId === 1)
  }, [bundles])

  const expired = useMemo(() => {
    if (!ibselBundle) return true
    return !isBefore(new Date(), new Date(ibselBundle.expiresAt))
  }, [ibselBundle])

  return (
    <Wrapper open={open}>
      <Sidebar open={open}>
        {!isFetchingUniversities && universities ? (
          <Logo open={open} to='/' onClick={() => setOpen(false)}>
            <Cover
              src={getImgixUrl(`universities/${universities.id}/image`, {
                w: 250,
                h: 250,
              })}
            />
          </Logo>
        ) : (
          <Logo open={open} to='/' onClick={() => setOpen(false)}>
            <LogoSVG />
          </Logo>
        )}
        <Opener open={open} onClick={() => setOpen(!open)}>
          <ChevronLeft size='15' />
        </Opener>
        <MenuLink open={open} to='/videos' onClick={() => setOpen(false)}>
          <Play size='16' />
          {open && 'Vídeos'}
          <MenuToolTip>Vídeos</MenuToolTip>
        </MenuLink>
        <MenuLink open={open} to='/exercicios' onClick={() => setOpen(false)}>
          <PencilAlt size='16' />
          {open && 'Exercícios'}
          <MenuToolTip>Exercícios</MenuToolTip>
        </MenuLink>
        <MenuLink open={open} to='/flashcards' onClick={() => setOpen(false)}>
          <PuzzlePiece size='16' />
          {open && 'Flashcard'}
          <MenuToolTip>Flashcard</MenuToolTip>
        </MenuLink>
        <MenuLink open={open} to='/livros' onClick={() => setOpen(false)}>
          <Book size='16' />
          {open && 'Livros'}
          <MenuToolTip>Livros</MenuToolTip>
        </MenuLink>
        {/* <MenuLink open={open} to='/comunidade' onClick={() => setOpen(false)}>
          <Comments size='16' />
          {open && 'Comunidade'}
          <MenuToolTip>Comunidade</MenuToolTip>
  </MenuLink>*/}
        <MenuLink open={open} to='/certificados' onClick={() => setOpen(false)}>
          <Certificate size='16' />
          {open && 'Certificados'}
          <MenuToolTip>Certificados</MenuToolTip>
        </MenuLink>
        <MenuLink
          open={open}
          to='/laboratorio-virtual'
          onClick={() => setOpen(false)}
        >
          <Microscope size='16' />
          {open && 'Laboratório virtual'}
          <MenuToolTip>Laboratório virtual</MenuToolTip>
        </MenuLink>
        <MenuLink
          open={open}
          to='/estudos-de-caso'
          onClick={() => setOpen(false)}
        >
          <ClinicMedical size='16' />
          {open && 'Casos Clínicos'}
          <MenuToolTip>Casos Clínicos</MenuToolTip>
        </MenuLink>
        {/*{!expired && (
          <MenuAnchor
            open={open}
            href='https://chat.whatsapp.com/JCMnlTV47YU85zYSXd67Qp'
            target='blank'
          >
          <Whatsapp size='16' />
            {open && 'Grupo de Monitoria'}
            <MenuToolTip>Grupo de Monitoria</MenuToolTip>
          </MenuAnchor>
        )}*/}
        <MenuLink
          open={open}
          to='/listas-de-reproducao'
          onClick={() => setOpen(false)}
        >
          <PlaylistPlay size='16' />
          {open && 'Listas de Reprodução'}
          <MenuToolTip>Listas de Reprodução</MenuToolTip>
        </MenuLink>
        {!expired && (
          <ExpirationBadge open={open} onClick={() => setOpen(false)}>
            <Key color='ffca3a' size='16' />
            {open &&
              `Expira em ${differenceInCalendarDays(
                ibselBundle.expiresAt,
                new Date(),
              )} dias`}
            <MenuToolTip>
              Expira em{' '}
              {differenceInCalendarDays(ibselBundle.expiresAt, new Date())} dias
            </MenuToolTip>
          </ExpirationBadge>
        )}
        {expired && (
          <MenuFeaturedLink
            open={open}
            to='/assinatura'
            onClick={() => setOpen(false)}
          >
            <CreditCard size='16' />
            {open && 'Assine Já!'}
            <MenuToolTip>Assine Já!</MenuToolTip>
          </MenuFeaturedLink>
        )}
        <MenuDiv open={open} onClick={logout}>
          <SignOutAlt size='16' />
          {open && 'Sair'}
          <MenuToolTip>Sair</MenuToolTip>
        </MenuDiv>
      </Sidebar>
      <Main>
        <Topbar hamburgerOpen={open} onHamburgerClick={() => setOpen(!open)} />
        {!emailConfirmed && (
          <EmailBanner>
            <b>Atenção!</b> Confirme seu email para continuar utilizando a
            plataforma.
          </EmailBanner>
        )}
        <Container>{children}</Container>
      </Main>
    </Wrapper >
  )
}
