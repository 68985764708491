import styled, { css } from 'styled-components'
import Modal from 'react-modal'
import { Close } from '@styled-icons/evil/Close'
import SelectComponent from 'routes/Anonymous/components/Select'
import InputComponent from 'routes/Anonymous/components/Input'
import TextareaComponent from 'routes/Anonymous/components/Textarea'
import SubmitComponent from 'routes/Anonymous/components/Submit'

export const AskQuestionBtn = styled.div`
  border-radius: 3px;
  color: rgb(30 93 136);
  border: solid 1px rgb(30 93 136);
  cursor: pointer;
  width: fit-content;
  padding: 10px 20px;
  &:hover {
    background: rgb(30 93 136);
    color: white;
  }
`
export const ModalOverlayStyles = css`
  z-index: 100;
`
export const QuestionModal = styled(Modal)`
  background: white;
  width: 100%;
  max-width: 580px;
  position: absolute;
  left: calc(50% - 290px);
  top: 100px;
  outline: none;
  max-height: calc(100vh - 40px);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  @media only screen and (max-width: 565px) {
    width: calc(100vw - 30px);
    left: 15px;
  }
`

export const ModalTitle = styled.div`
  font-size: 22px;
  flex: 1;
`
export const CloseModal = styled(Close).attrs({ size: 34 })`
  background: rgba(0, 0, 0, 0.15);
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  &:hover {
    opacity: 0.7;
  }
`
export const ModalHeader = styled.header`
  padding: 16px;
  display: flex;
  align-items: center;
  background: rgb(30 93 136);
  color: white;
`

export const Submit = styled(SubmitComponent)``
export const ModalFooter = styled.footer`
  padding: 16px;
  border-top: 1px solid rgb(238, 238, 238);
  display: flex;
  justify-content: flex-end;
`

export const Field = styled.div`
  background: white;
  /* border-radius: 8px; */
  border: 1px solid rgb(227, 227, 227);
  margin-bottom: 10px;
  & > div {
    margin-bottom: 0;
  }
`
export const Input = styled(InputComponent)`
  font-family: 'Montserrat', sans-serif;
  margin: 0;
`
export const Select = styled(SelectComponent)`
  font-family: 'Montserrat', sans-serif;
  margin: 0;
`
export const Textarea = styled(TextareaComponent)`
  font-family: 'Montserrat', sans-serif;
  min-height: 60px;
`
export const ModalContent = styled.section`
  padding: 16px;
`
