import React, { useCallback, useMemo, useState, useEffect } from 'react'
import { Link } from 'react-router-dom'
import styled, { css } from 'styled-components'
import {
  FilePdf,
  VolumeOff,
  VolumeUp,
  Lock,
  CloudDownloadAlt,
  BookOpen,
} from 'styled-icons/fa-solid'

import UserSubjectAPI from 'datasources/userSubject'
import FlexColumn from 'components/FlexColumn'
import Tabs from 'components/Tabs'
import mixpanel from 'utils/mixpanel'

import byId from 'utils/byId'
import FlexRow from 'components/FlexRow'
import useVimeo from 'hooks/useVimeo'

import useAppCtx from 'hooks/useAppCtx'
import useUniversityCtx from 'hooks/useUniversityCtx'
import useTopicCtx from '../hooks/useTopicCtx'

import Rating from './Rating'
import Lesson from './Lesson'
import getCourseName from 'utils/courseField'

const RatingWrapper = styled(FlexRow)`
  align-items: center;
  @media only screen and (max-width: 750px) {
    padding-left: 10px;
  }
`

const Wrapper = styled(FlexRow)`
  background-color: #f5f9fa;
  max-width: 1780px;
  @media only screen and (max-width: 750px) {
    flex-direction: column;
    margin-left: -10px;
    margin-right: -10px;
    width: calc(100% + 20px);
  }
`

const MaterialsWrapper = styled(FlexColumn)`
  flex: 1;
  padding: 60px;
  @media only screen and (max-width: 750px) {
    padding: 20px;
  }
`

const Container = styled(FlexRow)`
  ${({ small }) =>
    small &&
    css`
      flex-direction: column-reverse;
      width: 460px;
      border-left: solid 1px #e8eaee;
    `}
  @media only screen and (max-width: 750px) {
    flex-direction: column-reverse;
    width: 100%
    border-left: none;
  }
`

const SideContainer = styled(FlexRow)`
  max-width: 500px;
  @media only screen and (max-width: 750px) {
    max-width: none;
  }
`

const MainContainer = styled(FlexColumn)`
  padding-bottom: 90px;
  padding-left: 30px;
  padding-right: 30px;
  padding-top: 20px;
  @media only screen and (max-width: 750px) {
    border-bottom: solid 1px #e8eaee;
    padding-bottom: 20px;
    padding-left: 0;
    padding-right: 0;
    padding-top: 0;
  }
`

const VideosList = styled(FlexColumn)`
  position: relative;
  border-right: solid 1px #e8eaee;
  ${({ small }) =>
    small &&
    css`
      border-right: none;
    `}
  @media only screen and (max-width: 750px) {
    border-right: none;
  }
`

const VideoArea = styled(FlexColumn)`
  position: relative;
  margin-bottom: 10px;
  @media only screen and (max-width: 750px) {
    flex-direction: column-reverse;
    margin-bottom: 0;
  }
`

const Player = styled.div`
  position: relative;
  padding-top: 56.25%;
  height: 0;
  overflow: hidden;
  & > iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
`

const LessonTitle = styled.h2`
  color: #9da3a8;
  font-size: 18px;
  font-weight: 500;
  line-height: 24px;
  margin-bottom: 5px;
  margin-top: 0;
  @media only screen and (max-width: 750px) {
    margin-left: 10px;
    margin-right: 10px;
    margin-top: 20px;
  }
`

const SubjectTitle = styled.h1`
  color: #3f424a;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 20px;
  margin-top: 0;
  @media only screen and (max-width: 750px) {
    margin-left: 10px;
    margin-right: 10px;
  }
`

// const Document = styled.a`
//   color: #3f424a;
//   display: flex;
//   flex-diretion: column;
//   line-height: 30px;
//   margin-left: auto;
//   margin-top: 10px;
//   text-decoration: underline;
//   & > svg {
//     height: 30px;
//     margin-right: 10px;
//     width: auto;
//     flex-shrink: 0;
//     color: #ff595e;
//   }
// `

const Unmute = styled.div`
  background-color: rgba(23, 35, 34, 0.74902);
  border-radius: 5px;
  color: #FFFFFF;
  cursor: pointer;
  padding: 10px 20px;
  position: absolute;
  right: 10px;
  top: 10px;
  z-index: 10;
  &:hover {
    background-color: #1CACE3;
  }
`

const Restricted = styled(Link)`
  align-items: center;
  backdrop-filter: blur(5px);
  background-color: rgba(0, 0, 0, 0.05);
  color: #ffffff;
  cursor: pointer;
  display: flex;
  flex-direction: column;
  font-size: 24px;
  height: 100%;
  justify-content: center;
  left: 0;
  position: absolute;
  text-decoration: none;
  top: 0;
  width: 100%;
  z-index: 12;
  padding: 20px;
  text-align: center;
  & > div {
    background-color: #1cace3;
    color: #ffffff;
    font-size: 26px;
    padding: 10px 20px;
    @media only screen and (max-width: 565px) {
      font-size: 18px;
    }
  }
  @media only screen and (max-width: 565px) {
    font-size: 18px;
  }
`

// const DownloadButton = styled.a`
//   text-decoration: none;
//   display: block;
//   background-color: #1cace3;
//   color: white;
//   text-align: center;
//   font-size: 18px;
//   padding: 10px 0;
//   border-top-left-radius: 5px;
// `
const TopicTabs = styled(Tabs)`
  height: 40px;
  margin-bottom: 40px;
`

const Tab = styled(Tabs.item)`
  background-color: #e8eaee;
  color: #044c74;
  cursor: pointer;
  font-size: 14px;
  font-weight: bold;
  line-height: 36px;
  margin-right: 0px;
  padding: 11px 20px;
  text-align: center;
  text-transform: uppercase;
  height: 60px;
  ${({ selected }) =>
    selected &&
    css`
      border-top-color: #044c74;
      border-top: 1px solid;
      background-color: #ffffff;
      color: #044c74;
      cursor: pointer;
    `}
  ${({ disabled }) =>
    disabled &&
    css`
    border-color #9da3a8;
    background-color: #9da3a8;
    color: white;
    cursor: not-allowed;
  `}
`

const MaterialItem = styled.a`
  border-radius: 8px;
  border: solid 1px #e8eaee;
  color: #9da3a8;
  padding: 9px 12px;
  margin-bottom: 12px;
  display: flex;
  flex-direction: row;
  align-items: center;
  cursor: pointer;
  text-decoration: none;
  & > svg:first-child {
    height: 30px;
    margin-right: 10px;
    width: auto;
    flex-shrink: 0;
    color: #ff595e;
  }
  & > svg:last-child {
    height: 30px;
    margin-left: auto;
    width: auto;
    flex-shrink: 0;
    color: #1cace3;
  }
`

const TranscriptLink = styled.div`
  color: #9da3a8;
  cursor: pointer;
  font-size: 16px;
  margin-top: 10px;
  margin-left: auto;
  display: flex;
  align-items: center;
  &:hover {
    text-decoration: underline;
  }
  & > svg {
    margin-right: 10px
  }
`

const Transcript = styled.div`
  color: #676a74;
  font-size: 18px;
  font-weight: 400;
  line-height: 1.75;
  margin-top: 40px;
  margin-bottom: 50px;
  white-space: pre-wrap;
  border-top: solid 1px #e8eaee;
  padding-top: 20px;
  max-height: 300px;
  overflow-y: scroll;
`

export default function ({ history }) {
  const ctx = useAppCtx()
  const [selectedTab, setSelectedTab] = useState('videos')
  const { universities } = useUniversityCtx()

  // const {
  //   auth: { user },
  // } = ctx

  const { auth } = ctx

  const { topic, materials, currLesson, currSubject } = useTopicCtx()

  const isSubjectRestricted = useCallback(
    subject => {
      return subject.position !== 0 && topic.trial
    },
    [topic],
  )

  const [transcriptOpen, setTranscriptOpen] = useState(false)
  const [isRestricted, setIsRestricted] = useState(false)
  const [mixpanelTracked, setMixpanelTracked] = useState(false)
  const [justWatched, setJustWatched] = useState([])
  const [recentRatings, setRecentRatings] = useState({})

  const nextLesson = useMemo(() => {
    const idx = topic.lessons.findIndex(byId(currLesson.id))
    return topic.lessons[idx + 1]
  }, [topic, currLesson])

  const nextSubject = useMemo(() => {
    const idx = currLesson.subjects.findIndex(byId(currSubject.id))
    return currLesson.subjects[idx + 1]
  }, [currLesson, currSubject])

  const {
    ref,
    setVimeoId,
    setEndedCallback,
    isPlaying,
    isMuted,
    play,
    pause,
    mute,
    unmute,
    ready,
  } = useVimeo({ debug: true })

  const onRate = useCallback(
    rating => {
      setRecentRatings({ ...recentRatings, [currSubject.id]: rating })

      UserSubjectAPI.putRating(ctx, currSubject.id, rating)
    },
    [ctx, currSubject.id, recentRatings],
  )

  const onEnded = useCallback(() => {
    setJustWatched([...justWatched, currSubject.id])

    UserSubjectAPI.postWatched(ctx, currSubject.id)

    if (nextSubject) {
      history.push(
        `/disciplinas/${topic.id}/modulos/${currLesson.id}/assuntos/${nextSubject.id}`,
      )
    } else if (nextLesson) {
      history.push(
        `/disciplinas/${topic.id}/modulos/${nextLesson.id}/assuntos/${nextLesson.subjects[0].id}`,
      )
    }
  }, [
    ctx,
    history,
    justWatched,
    topic,
    currSubject,
    currLesson,
    nextSubject,
    nextLesson,
  ])

  useEffect(() => {
    if (currSubject) {
      if (isSubjectRestricted(currSubject)) {
        setEndedCallback(() => {})
      } else {
        setEndedCallback(() => onEnded)
      }
    }
  }, [isSubjectRestricted, currSubject, setEndedCallback, onEnded, play])

  useEffect(() => {
    if (currSubject) {
      if (isSubjectRestricted(currSubject)) {
        setIsRestricted(true)
        setVimeoId('397339858')
      } else {
        setIsRestricted(false)
        setVimeoId(currSubject.vimeoId)
      }
    }
  }, [isSubjectRestricted, currSubject, setVimeoId])

  useEffect(() => {
    if (
      !mixpanelTracked &&
      topic &&
      currLesson &&
      currSubject &&
      universities
    ) {
      setMixpanelTracked(true)
      mixpanel({ auth }, 'Action_Entered_Class', {
        Topic_Name: topic.name,
        Lesson_Name: currLesson.name,
        Subject_Name: currSubject.name,
        University: universities.name,
        Field: getCourseName(),
      })
    }
  }, [auth, topic, currLesson, currSubject, mixpanelTracked, universities])

  useEffect(() => {
    if (mixpanelTracked && topic && currLesson && currSubject && universities) {
      mixpanel({ auth }, 'Action_Changed_Video', {
        Topic_Name: topic.name,
        Lesson_Name: currLesson.name,
        Subject_Name: currSubject.name,
        University: universities.name,
        Field: getCourseName(),
      })
    }
  }, [auth, topic, currLesson, currSubject, mixpanelTracked, universities])

  return (
    <>
      <TopicTabs>
        <Tab
          onClick={() => setSelectedTab('videos')}
          selected={selectedTab === 'videos'}
        >
          <Tabs.bullet />
          Vídeos
        </Tab>
        <Tab
          onClick={() => !topic.trial && setSelectedTab('material')}
          selected={selectedTab === 'material'}
        >
          <Tabs.bullet />
          Material
          {topic.trial && <Tabs.Lock />}
        </Tab>
      </TopicTabs>
      <Wrapper>
        {selectedTab === 'material' && (
          <MaterialsWrapper>
            {materials.map(material => (
              <MaterialItem
                href={`https://s3-sa-east-1.amazonaws.com/${process.env.REACT_APP_AWS_S3_BASE_PATH}/topics/${material.topicId}/materials/${material.id}/file`}
                target='blank'
              >
                <FilePdf size='20' />
                {material.title}
                <CloudDownloadAlt size='18' />
              </MaterialItem>
            ))}
          </MaterialsWrapper>
        )}
        <Container small={selectedTab === 'material'}>
          <SideContainer>
            <VideosList small={selectedTab === 'material'}>
              {topic.lessons.map((lesson, i) => (
                <Lesson
                  {...lesson}
                  key={lesson.id}
                  justWatched={justWatched}
                  isPlaying={isPlaying}
                  play={play}
                  pause={pause}
                  history={history}
                  ready={ready}
                  isSubjectRestricted={isSubjectRestricted}
                />
              ))}
            </VideosList>
          </SideContainer>
          <MainContainer>
            <VideoArea>
              <FlexColumn>
                <LessonTitle>{currLesson.name}</LessonTitle>
                <SubjectTitle>{currSubject.name}</SubjectTitle>
              </FlexColumn>
              <Player ref={ref}>
                {!isRestricted && (
                  <Unmute onClick={() => (isMuted ? unmute() : mute())}>
                    {isMuted ? <VolumeOff size='20' /> : <VolumeUp size='20' />}
                  </Unmute>
                )}
                {isRestricted && (
                  <Restricted to='/assinatura'>
                    <Lock size='70' />
                    <br />
                    Conteúdo exclusivo para assinantes.
                    <br />
                    <br />
                    <div>Assine já!</div>
                  </Restricted>
                )}
              </Player>
            </VideoArea>
            <RatingWrapper>
              <Rating
                onRate={onRate}
                rated={recentRatings[currSubject.id] || currSubject.rating}
              />
              {currSubject.transcript && (
                <TranscriptLink
                  onClick={() => setTranscriptOpen(!transcriptOpen)}
                >
                  <BookOpen size='16' /> Transcrição
                </TranscriptLink>
              )}
            </RatingWrapper>
            {currSubject.transcript && transcriptOpen && (
              <>
                <Transcript>{currSubject.transcript}</Transcript>
              </>
            )}
          </MainContainer>
        </Container>
      </Wrapper>
    </>
  )
}
