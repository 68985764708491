import React, { useState, useEffect } from 'react'
import { useParams } from 'react-router-dom'
import { Close } from '@styled-icons/evil/Close'
import styled from 'styled-components'

import getFileUrl from 'utils/getFileUrl'
import getMimeType from 'utils/getMime'

import SimulationsAPI from 'datasources/simulations'
import usePromise from 'hooks/usePromise'
import useAppCtx from 'hooks/useAppCtx'
import useLayoutCtx from 'hooks/useLayoutCtx'
import Preview from 'components/3DViewer'

const Container = styled.div`
  display: flex;
  flex: 1;
`

const Content = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  padding: 1px;
  ${({ theme }) => theme.media.min('1100px')} {
    flex-direction: row;
  }
`

const ViewerContainer = styled.div`
  flex: 1;
  height: 671px;
  max-height: 671px;
  max-width: 1075px;
  min-width: 50%;
  ${({ theme }) => theme.media.max('1100px')} {
    max-height: 38vh;
  }
  ${({ theme }) => theme.media.min('565px')} {
    position: relative;
  }
`

const DetailPanel = styled.div`
  display: flex;
  flex-direction: column;
  margin: 19px 0;
  width: 100%;
  ${({ theme }) => theme.media.min('1100px')} {
    width: 504px;
    margin: 0 0 0 19px;
  }
`

const Info = styled.div`
  width: 100%;
  max-height: 506px;
  overflow-y: auto;
  white-space: break-spaces;
  background: white;
  border-radius: 3px;
  box-shadow: 0 1px 1px rgba(97, 108, 121, 0.1),
    0 7px 18px 0 rgba(21, 111, 170, 0.21);
  padding: 15px;
  margin-bottom: 15px;
`

const Carousel = styled.div`
  display: flex;
  overflow-x: auto;
  padding-right: 40px;
  padding-bottom: 10px;
`

const Image = styled.img`
  flex: 1;
  margin: 0 7px;
  object-fit: cover;
  height: 150px;
  width: 150px;
  max-width: 150px;
  cursor: pointer;
  &:first-child {
    margin-left: 0;
  }
  &:last-child:after {
    content: '';
    display: block;
    height: 1px;
    position: absolute;
    right: -55px;
    width: 55px;
  }
  @media only screen and (max-width: 565px) {
    &:last-child:after {
      content: '';
      display: block;
      height: 1px;
      position: absolute;
      right: -20px;
      width: 20px;
    }
  }
`

const CloseModal = styled(Close).attrs({ size: 34 })`
  background: rgba(0, 0, 0, 0.55);
  color: gray;
  border-radius: 50%;
  cursor: pointer;
  padding: 5px;
  position: absolute;
  top: 10px;
  right: 10px;
  &:hover {
    opacity: 0.7;
  }
`

const ImgPreview = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
  background: gray;
  z-index: 999999;
  overflow: hidden;
  img {
    flex: 1;
    object-fit: contain;
    object-position: center;
    width: 0;
    height: fit-content;
    max-height: -moz-available;
    max-height: -webkit-fill-available;
    max-height: fill-available;
  }
`

const ImagePreview = ({ imgUrl, closePreview }) => (
  <ImgPreview>
    <CloseModal onClick={closePreview} />
    <img src={imgUrl} alt='Preview' />
  </ImgPreview>
)

function useSimulation(simulationId) {
  const ctx = useAppCtx()

  const { isResolved, result } = usePromise(function () {
    return SimulationsAPI.get(ctx, { simulationId })
  }, [])

  return {
    simulation: result && result.data,
    isFetchingSimulation: !isResolved,
  }
}

export const Simulation = () => {
  const [imgPreview, setImgPreview] = useState(null)
  const { simulationId, courseId, topicId } = useParams()
  const { setLayoutState } = useLayoutCtx()

  const { simulation = {}, isFetchingSimulation } = useSimulation(simulationId)
  const { title, assets = [], description } = simulation

  const { id: assetId } =
    (assets || []).find(obj => {
      return (obj || {}).mimeType === 'application/octet-stream'
    }) || {}

  const meshes =
    (assets || []).filter(obj => {
      return (obj || {}).mimeType === 'application/octet-stream'
    }) || {}

  const imgAssets =
    (assets || []).filter(obj => {
      return (obj || {}).mimeType === 'image/jpeg'
    }) || {}

  const models = []
  meshes.forEach(value => {
    models.push({
      id: value.id,
      url: getFileUrl(`/simulations/${simulationId}/assets/${value.id}`),
    })
  })
  const modelUrl = getFileUrl(`/simulations/${simulationId}/assets/${assetId}`)

  useEffect(() => {
    setLayoutState({
      title,
      subTitle: 'Laboratório Virtual',
      backLink: '/laboratorio-virtual/' + courseId + '/disciplinas/' + topicId,
    })
  }, [title, setLayoutState, courseId, topicId])

  return isFetchingSimulation ? null : (
    <Container>
      <Content>
        <ViewerContainer>
          {imgPreview && (
            <ImagePreview
              imgUrl={imgPreview}
              closePreview={() => setImgPreview(null)}
            />
          )}
          <Preview modelName={modelUrl} meshes={models} />
        </ViewerContainer>
        <DetailPanel>
          {description && <Info>{description}</Info>}
          <Carousel>
            {imgAssets.length > 0 &&
              imgAssets.map(({ id, mimeType }) => {
                const imgUrl = getFileUrl(
                  `/simulations/${simulationId}/assets/${id}.${getMimeType(
                    mimeType,
                  )}`,
                )
                return (
                  <Image
                    key={id}
                    src={imgUrl}
                    onClick={() => setImgPreview(imgUrl)}
                  />
                )
              })}
          </Carousel>
        </DetailPanel>
      </Content>
    </Container>
  )
}

export default Simulation
