import React, { useCallback } from 'react'
import { useHistory, Link } from 'react-router-dom'
import styled from 'styled-components'
import { ChevronLeft, Bars, Times } from 'styled-icons/fa-solid'
import useLayoutCtx from 'hooks/useLayoutCtx'
import { Autocomplete, ALGOLIA_INDEX } from './AlgoliaAutocomplete'

import algoliasearch from 'algoliasearch/lite'

import { InstantSearch } from 'react-instantsearch-hooks'

const Container = styled.div`
  display: flex;
  align-items: center;

  @media only screen and (max-width: 565px) {
    width: 100%;
    justify-content: space-between;
  }
`

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 10px;
  background-color: #044c74;
  padding: 0 30px;
  width: 100%;
  height: 80px;

  @media only screen and (max-width: 565px) {
    flex-direction: column;
    padding: 0;
    height: unset;
    padding: 10px;
  }
`

const Title = styled.div`
  align-self: center;
  color: #ffffff;
  cursor: default;
  font-size: 30px;
  line-height: 1.2;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;

  @media only screen and (max-width: 992px) {
    font-size: 24px;
    width: 100%;
    margin-left: 10px;
  }
`

const SubTitle = styled.div`
  align-self: center;
  color: #9da3a8;
  cursor: default;
  font-size: 16px;
  margin-left: 10px;
  overflow: hidden;
  text-overflow: ellipsis;
  vertical-align: bottom;
  white-space: nowrap;
  @media only screen and (max-width: 992px) {
    font-size: 12px;
    width: 100%;
  }
`

const TitleWrapper = styled.div`
  display: flex;
  min-width: 0;
  width: 100%;
  @media only screen and (max-width: 992px) {
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
  }
`

const BackLink = styled(Link)`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  color: #ffffff;
`

const BackButton = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  color: #ffffff;
  cursor: pointer;
`

const HamburgerButton = styled.div`
  display: none;
  @media only screen and (max-width: 565px) {
    flex-direction: column;
    display: flex;
    align-items: center;
    justify-content: center;
    width: 50px;
    color: #ffffff;
    cursor: pointer;
    margin-left: auto;
    z-index: 999999;
  }
`

const searchClient = algoliasearch(
  'FW6WWYVU9C',
  '1c1f9c4531bc031e8bd5f44880420d37',
)

export function Topbar({ hamburgerOpen = false, onHamburgerClick = () => {} }) {
  const history = useHistory()
  const { layoutState } = useLayoutCtx()

  const AutocompleteCallback = useCallback(() => {
    return (
      <Autocomplete
        searchClient={searchClient}
        placeholder='Buscar aulas'
        detachedMediaQuery='none'
        openOnFocus
      />
    )
  }, [])

  return (
    <>
      <Wrapper>
        <Container>
          {layoutState.backLink && layoutState.backLink !== true && (
            <BackLink to={layoutState.backLink}>
              <ChevronLeft size='20' />
            </BackLink>
          )}
          {layoutState.backLink === true && (
            <BackButton onClick={() => history.goBack()}>
              <ChevronLeft size='20' />
            </BackButton>
          )}
          {layoutState.title && (
            <TitleWrapper>
              <Title>{layoutState.title}</Title>
              <SubTitle>{layoutState.subTitle}</SubTitle>
            </TitleWrapper>
          )}

          <HamburgerButton onClick={onHamburgerClick}>
            {hamburgerOpen ? <Times size='20' /> : <Bars size='20' />}
          </HamburgerButton>
        </Container>

        {layoutState.title && (
          <InstantSearch
            searchClient={searchClient}
            indexName={ALGOLIA_INDEX}
            routing
          >
            <AutocompleteCallback
              searchClient={searchClient}
              placeholder='Buscar aulas'
              detachedMediaQuery='none'
              openOnFocus
            />
          </InstantSearch>
        )}
      </Wrapper>
    </>
  )
}

export default Topbar
